import React, { useContext, useEffect, useRef, useState } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import { QsmSearchResults, QsmSearchResultType, search } from "../../services/qsm-service";
import { TideItemForCountryConnection, TideItemForTeamConnection, TideItemForToernooiConnection, TideItemForWedstrijdConnection } from "../../types";
import translate from "../../utils/translate";
import Icon from "../icon";
import Link from "../link";
import translations from "./translations.json";
import useOnClickOutside from "../../utils/hooks/use-on-click-outside";

interface QsmProps {}

const Qsm: React.FC<QsmProps> = () => {
  const [query, setQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState<QsmSearchResults | undefined>(undefined);

  const { language, qsmActive, setQsmActive } = useContext<GlobalContextType>(GlobalContext);
  const { teams, tournaments, games, countries } = useContext<ComponentContextType>(ComponentContext);

  const qsmRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(qsmRef, () => {
    setQsmActive(false);
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const qsmResults = search(query, teams ?? [], tournaments ?? [], games ?? [], countries ?? []);
      setFilteredResults(qsmResults);
    }, 250);

    return () => clearTimeout(delayDebounceFn);
  }, [query, teams, tournaments, games, countries]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setQuery(e.target.value);
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    // timeout zetten aangezien anders de div al sluit voor de action gedaan is
    setTimeout(() => {
      e.preventDefault();
      setQsmActive(!qsmActive);
    }, 1000);
  };

  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputElement.current && typeof window !== "undefined") {
      if (window.innerWidth <= 575) {
        inputElement.current.onfocus = () => {
          window.scrollTo(0, 0);
          document.body.scrollTop = 0;
        };
      }
    }
  });

  return (
    <div className="qsm" ref={qsmRef}>
      <form className="form form--qsm">
        <div className="form__row">
          <div className="form__group qsm__product-search">
            <div className="qsm__trigger">
              <input
                ref={inputElement}
                type="text"
                className="form__input"
                placeholder={translate(
                  translations,
                  language,
                  process.env.WEBSITE === "Sportreizen" ? "PLACEHOLDER_SPORTREIZEN" : "PLACEHOLDER_VOETBALREIZEN"
                )}
                data-lpignore="true"
                value={query}
                onChange={handleChange}
                onFocus={() => setQsmActive(true)}
              />
              <Icon name="ticket" />
            </div>
            <div className={`qsm__panel ${qsmActive ? "qsm__panel--active" : ""}`}>
              <div className="qsm__header">
                <Icon name="ticket" />
                <h5 className="qsm__heading">
                  {translate(translations, language, process.env.WEBSITE === "Sportreizen" ? "TITLE_SPORTREIZEN" : "TITLE_VOETBALREIZEN")}
                </h5>
                <button type="button" className="qsm__close-button" onClick={() => setQsmActive(false)}></button>
              </div>

              <div className="qsm__body" onClick={handleClick}>
                {!filteredResults?.searchResults.length && (
                  <>
                    {filteredResults?.popularResults
                      .filter((s) => s.type === QsmSearchResultType.country)
                      .map((country) => (
                        <Link key={country.key} className="qsm__product" path={country.path}>
                          <div className="qsm__product-header">
                            <p className="qsm__product-heading">{country.title}</p>
                            <p className="qsm__product-type">{translate(translations, language, "COUNTRY_TYPE")}</p>
                          </div>
                        </Link>
                      ))}
                    {filteredResults?.popularResults
                      .filter((s) => s.type === QsmSearchResultType.tournament)
                      .map((tournament) => (
                        <Link key={tournament.key} className="qsm__product" path={tournament.path}>
                          <div className="qsm__product-header">
                            <p className="qsm__product-heading">{tournament.title}</p>
                            <p className="qsm__product-type">{translate(translations, language, "TOURNAMENT_TYPE")}</p>
                          </div>
                        </Link>
                      ))}
                    {filteredResults?.popularResults
                      .filter((s) => s.type === QsmSearchResultType.team)
                      .map((team) => (
                        <Link key={team.key} className="qsm__product" path={team.path}>
                          <div className="qsm__product-header">
                            <p className="qsm__product-heading">{team.title}</p>
                            <p className="qsm__product-type">{translate(translations, language, "TEAM_TYPE")}</p>
                          </div>
                        </Link>
                      ))}
                    {filteredResults?.popularResults
                      .filter((s) => s.type === QsmSearchResultType.game)
                      .map((game) => (
                        <Link key={game.key} className="qsm__product" path={game.path}>
                          <div className="qsm__product-header">
                            <p className="qsm__product-heading">{game.title}</p>
                            <p className="qsm__product-type">
                              {translate(translations, language, process.env.WEBSITE === "Sportreizen" ? "EVENT_TYPE" : "GAME_TYPE")}
                            </p>
                          </div>
                        </Link>
                      ))}
                  </>
                )}
                {filteredResults?.searchResults
                  .filter((s) => s.type === QsmSearchResultType.country)
                  .map((country) => (
                    <Link key={country.key} className="qsm__product" path={country.path}>
                      <div className="qsm__product-header">
                        <p className="qsm__product-heading">{country.title}</p>
                        <p className="qsm__product-type">{translate(translations, language, "COUNTRY_TYPE")}</p>
                      </div>
                    </Link>
                  ))}
                {filteredResults?.searchResults
                  .filter((s) => s.type === QsmSearchResultType.tournament)
                  .map((tournament) => (
                    <Link key={tournament.key} className="qsm__product" path={tournament.path}>
                      <div className="qsm__product-header">
                        <p className="qsm__product-heading">{tournament.title}</p>
                        <p className="qsm__product-type">{translate(translations, language, "TOURNAMENT_TYPE")}</p>
                      </div>
                    </Link>
                  ))}
                {filteredResults?.searchResults
                  .filter((s) => s.type === QsmSearchResultType.team)
                  .map((team) => (
                    <Link key={team.key} className="qsm__product" path={team.path}>
                      <div className="qsm__product-header">
                        <p className="qsm__product-heading">{team.title}</p>
                        <p className="qsm__product-type">{translate(translations, language, "TEAM_TYPE")}</p>
                      </div>
                    </Link>
                  ))}
                {filteredResults?.searchResults
                  .filter((s) => s.type === QsmSearchResultType.game)
                  .map((game) => (
                    <Link key={game.key} className="qsm__product" path={game.path}>
                      <div className="qsm__product-header">
                        <p className="qsm__product-heading">{game.title}</p>
                        <p className="qsm__product-type">
                          {translate(translations, language, process.env.WEBSITE === "Sportreizen" ? "EVENT_TYPE" : "GAME_TYPE")}
                        </p>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          <div className="form__group form__group--submit qsm__submit">
            <button type="submit" className="cta" title="Toon reizen">
              <Icon name="search" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Qsm;

export interface QsmData {
  qsmTeams: TideItemForTeamConnection;
  qsmTournaments: TideItemForToernooiConnection;
  qsmGames: TideItemForWedstrijdConnection;
  qsmCountries: TideItemForCountryConnection;
}
